$(() => {
    $(".gallery--carousel").slick({
        dots: true,
        prevArrow: false,
        nextArrow: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        // responsive: [
        //     {
        //         breakpoint: 1440,
        //         settings: {
        //             slidesToShow: 2,
        //             slidesToScroll: 1,
        //         }
        //     }
        // ],
        customPaging: function(slider, i) {
            let current = i + 1;
            current = current < 10 ? + current : current;

            let total = slider.slideCount;
            total = total < 10 ? + total : total;

            return (
                '<button type="button" role="button" tabindex="0" class="slick-dots-button">\
                <span class="slick-dots-current">' + current + '</span>\
			<span class="slick-dots-separator"></span>\
			<span class="slick-dots-total">' + total + '</span>\
		</button>'
            );
        }
    });

    $(".gallery--carousel-3").slick({
        dots: true,
        prevArrow: $('.slick-prev-3'),
        nextArrow: $('.slick-next-3'),
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                    draggable: true,
                    arrows: false,
                    prevArrow: false,
                    nextArrow: false,

                }
            }
        ],
        customPaging: function(slider, i) {
            let current = i + 1;
            current = current < 10 ? + current : current;

            let total = slider.slideCount;
            total = total < 10 ? + total : total;

            return (
                '<button type="button" role="button" tabindex="0" class="slick-dots-2-button">\
                <span class="slick-dots-2-current">' + current + '</span>\
			<span class="slick-dots-2-separator"></span>\
			<span class="slick-dots-2-total">' + total + '</span>\
		</button>'
            );
        }
    });

    $(".gallery--carousel-4").slick({
        dots: false,
        prevArrow: $('.slick-prev-4'),
        nextArrow: $('.slick-next-4'),
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                }
            }
        ],
        // customPaging: function(slider, i) {
        //     let current = i + 1;
        //     current = current < 10 ? + current : current;
        //
        //     let total = slider.slideCount;
        //     total = total < 10 ? + total : total;
        //
        //     return (
        //         '<button type="button" role="button" tabindex="0" class="slick-dots-2-button">\
        //         <span class="slick-dots-2-current">' + current + '</span>\
		// 	<span class="slick-dots-2-separator"></span>\
		// 	<span class="slick-dots-2-total">' + total + '</span>\
		// </button>'
        //     );
        // }
    });

    $(".slickDoor-slider").slick({
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: $('.slick-prev'),
        nextArrow: $('.slick-next')
    });

    $(".slickDoor-slider2").slick({
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: $('.slick-prev'),
        nextArrow: $('.slick-next')
    });

    $(".slickDoor-slider3").slick({
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.slick-prev'),
        nextArrow: $('.slick-next'),
        variableWidth: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    variableWidth: false,
                }
            }
        ],
    });
});