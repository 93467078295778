require('./block/sideBar');
require('./block/rightSideBar');
require('./block/sliderScroll');
require('./block/scrollBlock');
require('./block/tooltips');
require('./block/video');
require('./block/mask');
require('./block/ajax');
require('./block/showButton');

$(() => {
    let sticky = new Sticky('[data-sticky');
});


$(document).ready(function() {
    const queryString = window.location.search;

    if (queryString !== '') {
        // console.log(queryString);
        $('html, body').animate({
            scrollTop: $("#catalog").offset().top
        }, 10);
    }

//     let element = $(".catalog-filter a");
//     let height_el = element.offset().top;
//     let element_stop = $(".catalog-content");
//     let height_el_stop = element_stop.offset().top;
//
//     $(".catalog-filter").css({
//         "width": element.outerWidth(),
//         "height": element.outerHeight()
//     });
//
//     $(window).scroll(function() {
//         if($(window).scrollTop() > height_el_stop - element.outerHeight() - 30) {
//             element.css({
//                 "top": element.offset().top,
//             }).removeClass("fixed").addClass("absolute");
//         } else {
//             if($(window).scrollTop() > height_el) {
//                 element.addClass("fixed").removeClass("absolute").attr("style", "");
//             } else {
//                 element.removeClass("fixed absolute").attr("style", "");
//             }
//         }
//     });
});

