(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
$(() => {

    $('#filterForm').on('change', function() {
        $(this).closest('form').submit();
    });


    // document.querySelector('#catalog').scrollTo(0,0);

    // $('#more').click(function() {
    //     $.ajax({
    //         url: '/load-more',
    //         method: 'POST',
    //         data: {"startFrom" : startFrom, "type" : $('#doorType').val()},
    //     }).done(function(data){
    //          console.log(data.length);
    //         // console.log($('#doorType').val());
    //
    //         if (data.length > 0) {
    //             $("#articles").append(data).after(function() {
    //             });
    //         }
    //
    //         if (data.length < 5000) {
    //             $("#more").remove();
    //         }
    //
    //         startFrom += 6;
    //     });
    // });
});
},{}],2:[function(require,module,exports){
$(() => {
    $("#phone").mask("+7(999) 999-99-99");
    $("#phone1").mask("+7(999) 999-99-99");
    $("#phone2").mask("+7(999) 999-99-99");
});
},{}],3:[function(require,module,exports){
$(() => {
    let obj = $('.rightSideBar');
    let content = $('.rightSideBar-content');
    let obj_ovrly = $('.rightSideBar-overlay');
    let page = $('body');

    function toggle(e) {
        let target = $(e.currentTarget);
        if (!target.is('.rightSideBar') && !target.is('[href]')) {
            target = target.parents('.rightSideBar');
        }
        let id = target.attr('id') || target.attr('href').replace('#', '');

        obj.filter('[id="' + id + '"]').toggleClass('rightSideBar_visible');
        obj_ovrly.toggleClass('overlay_visible');
        page.toggleClass(' _noScroll');
    }

    $('.js-rightSideBarCtrl').click(toggle);
    content.click((e) => {
        e.stopPropagation();
    });
    obj.click(toggle);

});

$(() => {
    let obj = $('.rightSideBar2');
    let content = $('.rightSideBar2-content');

    function toggle(e) {
        let target = $(e.currentTarget);
        if (!target.is('.rightSideBar2') && !target.is('[href]')) {
            target = target.parents('.rightSideBar2');
        }
        let id = target.attr('id') || target.attr('href').replace('#', '');

        obj.filter('[id="' + id + '"]').toggleClass('rightSideBar2_visible');
    }

    $('.js-rightSideBarCtrl2').click(toggle);
    content.click((e) => {
        e.stopPropagation();
    });
    obj.click(toggle);
});
},{}],4:[function(require,module,exports){
$(() => {


});
},{}],5:[function(require,module,exports){
$(() => {

    $('.button-show').click(function () {
        $('#articles .showDoor:hidden').slice(0, 6).show();
        if ($('#articles .showDoor').length == $('#articles .showDoor:visible').length) {
            $('.button-show').hide();
        }
    });
});
},{}],6:[function(require,module,exports){
$(() => {
	let obj = $('.sideBar');
	let content = $('.sideBar-content');

	function toggle() {
		obj.toggleClass('sideBar_visible');
	}

	$('.js-sideBarCtrl').click(toggle);
	content.click((e) => {
		e.stopPropagation();
	});
	obj.click(toggle);


});


},{}],7:[function(require,module,exports){
$(() => {
    $(".gallery--carousel").slick({
        dots: true,
        prevArrow: false,
        nextArrow: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        // responsive: [
        //     {
        //         breakpoint: 1440,
        //         settings: {
        //             slidesToShow: 2,
        //             slidesToScroll: 1,
        //         }
        //     }
        // ],
        customPaging: function(slider, i) {
            let current = i + 1;
            current = current < 10 ? + current : current;

            let total = slider.slideCount;
            total = total < 10 ? + total : total;

            return (
                '<button type="button" role="button" tabindex="0" class="slick-dots-button">\
                <span class="slick-dots-current">' + current + '</span>\
			<span class="slick-dots-separator"></span>\
			<span class="slick-dots-total">' + total + '</span>\
		</button>'
            );
        }
    });

    $(".gallery--carousel-3").slick({
        dots: true,
        prevArrow: $('.slick-prev-3'),
        nextArrow: $('.slick-next-3'),
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                    draggable: true,
                    arrows: false,
                    prevArrow: false,
                    nextArrow: false,

                }
            }
        ],
        customPaging: function(slider, i) {
            let current = i + 1;
            current = current < 10 ? + current : current;

            let total = slider.slideCount;
            total = total < 10 ? + total : total;

            return (
                '<button type="button" role="button" tabindex="0" class="slick-dots-2-button">\
                <span class="slick-dots-2-current">' + current + '</span>\
			<span class="slick-dots-2-separator"></span>\
			<span class="slick-dots-2-total">' + total + '</span>\
		</button>'
            );
        }
    });

    $(".gallery--carousel-4").slick({
        dots: false,
        prevArrow: $('.slick-prev-4'),
        nextArrow: $('.slick-next-4'),
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                }
            }
        ],
        // customPaging: function(slider, i) {
        //     let current = i + 1;
        //     current = current < 10 ? + current : current;
        //
        //     let total = slider.slideCount;
        //     total = total < 10 ? + total : total;
        //
        //     return (
        //         '<button type="button" role="button" tabindex="0" class="slick-dots-2-button">\
        //         <span class="slick-dots-2-current">' + current + '</span>\
		// 	<span class="slick-dots-2-separator"></span>\
		// 	<span class="slick-dots-2-total">' + total + '</span>\
		// </button>'
        //     );
        // }
    });

    $(".slickDoor-slider").slick({
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: $('.slick-prev'),
        nextArrow: $('.slick-next')
    });

    $(".slickDoor-slider2").slick({
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: $('.slick-prev'),
        nextArrow: $('.slick-next')
    });

    $(".slickDoor-slider3").slick({
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.slick-prev'),
        nextArrow: $('.slick-next'),
        variableWidth: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    variableWidth: false,
                }
            }
        ],
    });
});
},{}],8:[function(require,module,exports){
$(() => {
    tippy('#myTooltip', {
        content: "Уточняйте подробности акции у наших <a href='/contacts' class='tooltips-a'>менеджеров</a>",
        placement: 'bottom',
        interactive: true,
        animation: 'scale',
        allowHTML: true
    });
});
},{}],9:[function(require,module,exports){
$(() => {

    const toggleButton = document.querySelector('.js-toggle');

    if (toggleButton) {
        const videoPlayer = document.getElementById('fullSizeVideo');
        const textBlock = document.getElementById('text');
        const videoOverlay = document.getElementById('video-overlay');

        const toggleVideo = (event) => {

            event.preventDefault();
            videoPlayer.controls = 'controls';
            videoPlayer.muted = !videoPlayer.muted;
            textBlock.style.display = 'none';
            videoOverlay.style.display = 'none';
            videoPlayer.volume = 0.9;
            videoPlayer.play();
        };

        toggleButton.addEventListener('click', toggleVideo);
    }

});
},{}],10:[function(require,module,exports){
require('./block/sideBar');
require('./block/rightSideBar');
require('./block/sliderScroll');
require('./block/scrollBlock');
require('./block/tooltips');
require('./block/video');
require('./block/mask');
require('./block/ajax');
require('./block/showButton');

$(() => {
    let sticky = new Sticky('[data-sticky');
});


$(document).ready(function() {
    const queryString = window.location.search;

    if (queryString !== '') {
        // console.log(queryString);
        $('html, body').animate({
            scrollTop: $("#catalog").offset().top
        }, 10);
    }

//     let element = $(".catalog-filter a");
//     let height_el = element.offset().top;
//     let element_stop = $(".catalog-content");
//     let height_el_stop = element_stop.offset().top;
//
//     $(".catalog-filter").css({
//         "width": element.outerWidth(),
//         "height": element.outerHeight()
//     });
//
//     $(window).scroll(function() {
//         if($(window).scrollTop() > height_el_stop - element.outerHeight() - 30) {
//             element.css({
//                 "top": element.offset().top,
//             }).removeClass("fixed").addClass("absolute");
//         } else {
//             if($(window).scrollTop() > height_el) {
//                 element.addClass("fixed").removeClass("absolute").attr("style", "");
//             } else {
//                 element.removeClass("fixed absolute").attr("style", "");
//             }
//         }
//     });
});


},{"./block/ajax":1,"./block/mask":2,"./block/rightSideBar":3,"./block/scrollBlock":4,"./block/showButton":5,"./block/sideBar":6,"./block/sliderScroll":7,"./block/tooltips":8,"./block/video":9}]},{},[10])

