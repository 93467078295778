$(() => {

    $('#filterForm').on('change', function() {
        $(this).closest('form').submit();
    });


    // document.querySelector('#catalog').scrollTo(0,0);

    // $('#more').click(function() {
    //     $.ajax({
    //         url: '/load-more',
    //         method: 'POST',
    //         data: {"startFrom" : startFrom, "type" : $('#doorType').val()},
    //     }).done(function(data){
    //          console.log(data.length);
    //         // console.log($('#doorType').val());
    //
    //         if (data.length > 0) {
    //             $("#articles").append(data).after(function() {
    //             });
    //         }
    //
    //         if (data.length < 5000) {
    //             $("#more").remove();
    //         }
    //
    //         startFrom += 6;
    //     });
    // });
});